<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
              class="mb-4"
            >
              H Ιστορία και η Φιλοσοφία μας
            </h2>
            <p class="subtitle-1">
              Η Σχολή Οδηγών Σταύρος Πλυτάς ιδρύθηκε το 1987 στην Αγία Παρασκευή, έχοντας πολυετή εμπειρία στον τομέα θεωρητικής και πρακτικής εκπαίδευσης υποψηφίων οδηγών.
              Κύριο μέλημα της σχολής αποτελεί η υπεύθυνη και συστηματική εκμάθηση των υποψηφίων οδηγών, με στόχο την πολυεπίπεδη εκπαίδευση τους τόσο στην πρακτική της οδήγησης αλλά και στην καλλιέργεια της ορθής οδηγικής συμπεριφοράς.
            </p>
            <p class="mb-5">
              Η εκμάθηση των οδηγών πραγματοποιείται από έμπειρους δασκάλους με τη χρήση ιδιοκτητών οχημάτων. Οι υπηρεσίες που παρέχονται αφορούν στην απόκτηση όλων των διπλωμάτων οδηγησης, όλων των κατηγοριών ερασιτεχνικών και επαγγελματικών διπλωμάτων.
              Η Σχολή Οδηγών Σταύρος Πλυτάς, με αρχή την επαγγελματική ακεραιότητα, επιτυγχάνει υψηλά ποσοστά επιτυχίας στις εξετάσεις
            </p>

            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/teacher.jpg" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <h2
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
          class="mb-4"
        >
          Τι λένε οι παλιοί μαθητές για εμάς
        </h2>
        <v-row>
          <v-col
            v-for="([name, says], i) in names"
            :key="i"
            cols="12"
            md="4"
          >
            <v-card
              class="mx-n3"
              color="transparent"
              flat
            >
              <v-card-title v-text="name" />
              <v-card-text
                v-text="says"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white">
      <v-container>
        <v-row>
          <v-col
            v-for="([icon, number, name], i) in experiences"
            :key="i"
            cols="6"
            md="3"
          >
            <div class="text-center">
              <v-icon
                size="64"
                class="mb-3"
                v-text="icon"
              />
              <div
                class="display-2 font-weight-bold mb-2"
                v-text="number"
              />
              <div
                class="text-uppercase"
                v-text="name"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      names: [
        ['Νάσσος Σ. - 2021', 'Σωστοί επαγγελματίες. Ο κ. Περικλής που μου έκανε μαθήματα είναι εξαιρετικός δάσκαλος, όντως ενδιαφερόταν για την σωστή εκμάθηση.  Έχει πολύ υπομονή και εξηγεί τα πάντα με πολύ απλό τρόπο για να καταλάβουμε την λογική πίσω από τα πάντα. Συστήνω ανεπιφύλακτα!!'],
        ['Pantokratoras 7 - 2021', 'Επαγγελματίες. Πέρα από το πρακτικό κομμάτι, δίνουν έμφαση και στα θεωρητικά μαθήματα, πράγμα που δεν συνηθίζεται. Και στο οικονομικό σωστοί, δεν πάνε να σε κλέψουν όπως συμβαίνει δυστυχώς συχνά σε πολλές σχολές..'],
        ['Βασίλης Σ. - 2021', 'τα λογια περιττα !! απλα οι καλυτεροι με καταρτισμενο προσωπικο'],
        ['Νικολία Τ.', 'Επαγγελματισμός, Επικοινωνία, Ποιότητα, Σχέση ποιότητας-τιμής'],
        ['Γεράσιμος Μ.', 'Επαγγελματισμός, Επικοινωνία, Ποιότητα'],
        ['Μάριος Β.', 'Επαγγελματισμός, Ποιότητα']
      ],
      experiences: [
        ['mdi-account-group', '75.000+', 'Μαθητες'],
        ['mdi-certificate', '100.000+', 'Διπλωματα'],
        ['mdi-timer-sand', '150.000+', 'Συνολικες ωρες διδασκαλιας'],
        ['mdi-car', '10', 'Οχηματα']
      ]
    })
  }
</script>
